export const greetings = [
  ["Hello", "Meow"],
  ["Bonjour", "Miaou"],
  ["Hola", "Miau"],
  ["Ciao", "Miao"],
  ["Olá", "Miau"],
  ["Kia Ora", "Meow"],
  ["Γειά σου", "Νιάου"],
  ["Здраво", "Мјау"],
  ["Привет", "Мяу"],
  ["你好", "喵"],
  ["Прывітанне", "Мяу"],
  ["Привіт", "Мяу"],
  ["Salam", "Myau"],
  ["Сәлеметсіз бе", "Мияу"],
  ["Cześć", "Miauk"],
  ["こんにちは", "ニャー"],
  ["Sveiki", "Mjau"],
  ["Hei", "Miau"],
  ["Tere", "Näu"],
  ["Habari", "Meow"],
  ["Hallå", "Mjau"],
];
