import { CreateButton } from "../modules/Buttons/CreateButton";
import "./video.css";

const Video = () => {
  return (
    <div>
      <h1>VIDEO</h1>
      <CreateButton />
    </div>
  );
};

export default Video;
